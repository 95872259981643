import { TR } from '../table/Table';
import SkeletonCell from './SkeletonCell';

interface RowProps {
    columns: number;
    isHeader?: boolean;
    textClassName?: string;
}

/** Display a row of loading cell animations */
export default function SkeletonRow({ columns, isHeader, textClassName }: RowProps) {
    const rowColumns = [...Array(columns)].map((_, index) => {
        return <SkeletonCell key={index} textClassName={textClassName} />;
    });

    if (isHeader) {
        return <th>{rowColumns}</th>;
    }
    return <TR>{rowColumns}</TR>;
}
